import { docReady } from "./_helper"
import { slick } from "slick-carousel"
import $ from "jquery"
const logoSlider = $(".logo-slider__slider")
const collectionSlider = $(".slider__products")
const artObjectSlider = $(".art__images")
const slider = $(".slider__wrapper")
const showcaseSlider = $(".showcase--slider")

docReady(() => {
  if (logoSlider.length > 0) {
    $(logoSlider).each(function () {
      const currentSlider = $(this)
      currentSlider.on("init", (event, slick) => {
        $(".slider__control ").addClass("slider__control--initialized")
      })

      currentSlider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        infinite: true,
        speed: 550,
        nextArrow: currentSlider.parent().find(".logo-slider-next"),
        prevArrow: currentSlider.parent().find(".logo-slider-prev"),
        responsive: [
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 1170,
            settings: {
              slidesToShow: 5,
            },
          },
        ],
      })
    })
  }

  if (collectionSlider.length > 0) {
    $(collectionSlider).each(function () {
      const currentSlider = $(this)
      currentSlider.on("init", (event, slick) => {
        $(".slider__control ").addClass("slider__control--initialized")
      })

      currentSlider.slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        mobileFirst: true,
        infinite: true,
        lazyLoad: "ondemand",
        speed: 550,
        nextArrow: currentSlider.parent().parent().find(".product__next"),
        prevArrow: currentSlider.parent().parent().find(".product__previous"),
        responsive: [
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 1170,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 6,
            },
          },
        ],
      })
    })
  }

  if (artObjectSlider.length > 0) {
    $(artObjectSlider).each(function () {
      const currentSlider = $(this)
      currentSlider.on("init", (event, slick) => {
        $(".slider__control ").addClass("slider__control--initialized")
      })

      currentSlider.slick({
        centerMode: true,
        centerPadding: "0px",
        slidesToShow: 1,
        infinite: false,
        nextArrow: currentSlider.parent().parent().find(".product__next"),
        prevArrow: currentSlider.parent().parent().find(".product__previous"),
        variableWidth: true,
        responsive: [
          {
            breakpoint: 580,
            settings: {
              infinite: true,
              variableWidth: false,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              variableWidth: true,
            },
          },
          {
            breakpoint: 1170,
            settings: {
              variableWidth: true,
            },
          },
          {
            breakpoint: 1440,
            settings: {
              variableWidth: true,
            },
          },
        ],
      })
    })
  }

  if (slider.length > 0) {
    $(slider).each(function () {
      const currentSlider = $(this)
      currentSlider.on("init", (event, slick) => {
        $(".slider__control ").addClass("slider__control--initialized")
      })

      currentSlider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        centerMode: true,
        infinite: true,
        centerPadding: "0px",
        speed: 550,
        nextArrow: currentSlider.parent().parent().find(".slider__next"),
        prevArrow: currentSlider.parent().parent().find(".slider__previous"),
        responsive: [
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      })
    })
  }

    if (showcaseSlider.length > 0) {
        $(showcaseSlider).each(function () {
            const currentSlider = $(this)
            currentSlider.on("init", (event, slick) => {
                $(".slider__control ").addClass("slider__control--initialized")
            })

            currentSlider.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                mobileFirst: true,
                speed: 550,
                autoplay: true,
                infinite: false,
                nextArrow: currentSlider.parent().parent().find(".slider__next"),
                prevArrow: currentSlider.parent().parent().find(".slider__previous"),
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                ],
            })
        })
    }

})
